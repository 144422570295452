import React, { useState, useEffect } from "react";
import styled from "styled-components";
//type
import StepInterface from "../stepInterface";
//components
import HumorCard from "../../_atoms/humorCard";
import RoundedButton from "../../_atoms/rounded-button";
import IconButton from "../../_atoms/button-icon";
import Navigation from "./navigation";

const Step = styled.div`
  @media (max-width: 1200px) {
    margin-top: 90px;
    margin-bottom: 50px;
  }
  .list {
    list-style: none;
    width: 100%;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    @media (min-width: 1001px) and (max-width: 1200px) {
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: 1fr 1fr;
      > div:last-of-type {
        grid-row-start: 2;
        grid-column-start: 1;
        grid-column-end: 5;
      }
    }
    @media (max-width: 1000px) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto;
    }
    @media (max-width: 767px) {
      grid-template-columns: 1fr;
      grid-template-rows: auto;
    }
    > div {
      margin: 20px;
    }
  }
`;

const StepTwo = ({ fields, setFields, ...props }: StepInterface) => {
  const [isDisable, setDisable] = useState<boolean>(true);
  useEffect(() => {
    fields.humor !== null && setDisable(false);
  }, [isDisable, fields.humor]);
  const handleChange = (value: number) => {
    setFields({ ...fields, humor: value });
    setDisable(false);
  };
  const nextStep = () => {
    props.handleStep("next");
  };
  return (
    <Step>
      <h1>Comment te sens-tu aujourd'hui&nbsp;?</h1>
      <div className="list">
        <HumorCard
          icon="lost.svg"
          label="Perdu•e"
          onClick={() => handleChange(1)}
          checked={fields.humor === 1}
        />
        <HumorCard
          icon="OMG.svg"
          label="OMG"
          onClick={() => handleChange(2)}
          checked={fields.humor === 2}
        />
        <HumorCard
          icon="smiling.svg"
          label="Bien"
          onClick={() => handleChange(3)}
          checked={fields.humor === 3}
        />
        <HumorCard
          icon="happy.svg"
          label="Au top"
          onClick={() => handleChange(4)}
          checked={fields.humor === 4}
        />
        <HumorCard
          icon="waow.svg"
          label="Je kiffe"
          onClick={() => handleChange(5)}
          checked={fields.humor === 5}
        />
      </div>

      <Navigation>
        <IconButton
          className="top-left"
          icon="arrow-previous.svg"
          text="Étape précédente"
          onClick={() => props.handleStep("previous")}
        />
        <RoundedButton
          type="next"
          onClick={() => nextStep()}
          disabled={isDisable}
        />
      </Navigation>
    </Step>
  );
};

export default StepTwo;
