import React, { useState, useEffect } from "react";
import styled from "styled-components";
//type
import StepInterface from "../stepInterface";
//components
import Textarea from "../../_atoms/textarea";
import RoundedButton from "../../_atoms/rounded-button";
import IconButton from "../../_atoms/button-icon";
import Navigation from "./navigation";

const Step = styled.div`
  @media (max-width: 1200px) {
    margin-top: 90px;
    margin-bottom: 50px;
  }
`;

const StepThree = ({ fields, setFields, ...props }: StepInterface) => {
  const [isDisable, setDisable] = useState<boolean>(true);
  useEffect(() => {
    fields.feedback !== null &&
      fields.feedback.trim().length >= 30 &&
      setDisable(false);
  }, [isDisable, fields.feedback]);

  const handleChange = (value: string) => {
    setFields({ ...fields, feedback: value });
    if (value.trim().length >= 30) {
      setDisable(false);
      props.defineErrorMessage && props.defineErrorMessage(false);
    } else {
      setDisable(true);
    }
  };
  return (
    <Step>
      <h1>
        Qu'est-ce qui te permettrait
        <br />
        d'apprécier davantage le module&nbsp;?
      </h1>
      <Textarea
        placeholder="Partages moi ici tes besoins, tes envies, tes craintes, tout ce que tu désires qui puisse m’aider à améliorer le module..."
        value={fields.feedback}
        handleChange={handleChange}
      />
      <Navigation>
        <IconButton
          className="top-left"
          icon="arrow-previous.svg"
          text="Étape précédente"
          onClick={() => props.handleStep("previous")}
        />
        <RoundedButton
          type="valid"
          onClick={() => props.handleStep("valid")}
          disabled={isDisable}
        />
        <IconButton
          icon="stamp.svg"
          text="Valider sans commentaire"
          onClick={() => props.handleStep("valid")}
        />
      </Navigation>
    </Step>
  );
};

export default StepThree;
