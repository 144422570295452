import React from "react";
import styled from "styled-components";

//typage
type IconButtonProps = {
  icon: string;
  text: string;
  onClick?: any;
  className?: string;
};

//style
const StyledIconButton = styled.button`
  border: none;
  font-size: 1.3rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  cursor: pointer;
  color: ${(props) => props.theme.colors.light.main};
  font-family: ${(props) => props.theme.fontFamily.content};
  transition: transform 0.3s ease;
  img {
    margin-right: 20px;
  }

  &.top-left {
    position: absolute;
    top: 90px;
    left: 0;
    @media (max-width: 1200px) {
      top: 30px;
    }
  }

  &:hover {
    transform: scale(110%);
  }
`;

//composant
const IconButton = ({ icon, text, ...props }: IconButtonProps) => (
  <StyledIconButton
    onClick={props.onClick !== undefined ? props.onClick : undefined}
    className={props.className ? props.className : ""}
  >
    <img src={`/assets/img/icons/${icon}`} alt="icon" />
    {text}
  </StyledIconButton>
);

export default IconButton;
