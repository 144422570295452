import React from "react";
import styled from "styled-components";
//type

const Step = styled.div``;

const ForbiddenStep = () => {
  return (
    <Step>
      <h1>Feedback non autorisé...</h1>
    </Step>
  );
};

export default ForbiddenStep;
