import React from "react";
import styled from "styled-components";

//typage
type TeaxareaProps = {
  placeholder: string;
  value?: string | any;
  handleChange?: any;
};

const StyledTextarea = styled.textarea`
  box-shadow: 0px 4px 46px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  width: 90%;
  max-width: 90%;
  min-height: 200px;
  padding: 20px;
  border: none;
  color: ${(props) => props.theme.colors.light.colorLight};
  font-family: ${(props) => props.theme.fontFamily.content};
  font-size: 1.5rem;
  &::placeholder {
    color: ${(props) => props.theme.colors.light.colorLight};
  }
`;

const Textarea = (props: TeaxareaProps) => (
  <StyledTextarea
    {...props}
    value={props.value != null ? props.value : ""}
    onChange={(event) => props.handleChange(event.target.value)}
  />
);

export default Textarea;
