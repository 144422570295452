import React from "react";
import ReactDOM from "react-dom";
import MultiStepsForm from "./components/form-steps";
import reportWebVitals from "./reportWebVitals";

//style
import { ThemeProvider } from "styled-components";
import { Main, theme, GlobalStyle } from "./style";

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <Main>
        <div className="container">
          <MultiStepsForm />
        </div>
      </Main>
      <GlobalStyle />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
