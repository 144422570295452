import React, { useState, useEffect } from "react";
import styled from "styled-components";
//type
import StepInterface from "../stepInterface";
//components
import Input from "../../_atoms/input";
import RoundedButton from "../../_atoms/rounded-button";
import IconButton from "../../_atoms/button-icon";
import Navigation from "./navigation";

const Step = styled.div``;

const StepOne = ({ fields, setFields, ...props }: StepInterface) => {
  const [isDisable, setDisable] = useState<boolean>(true);
  useEffect(() => {
    fields.name !== null && fields.name.trim().length >= 3 && setDisable(false);
  }, [isDisable, fields.name]);

  const handleChange = (value: string) => {
    setFields({ ...fields, name: value });
    value.trim().length >= 3 ? setDisable(false) : setDisable(true);
  };
  const nextStep = (validation: boolean = false) => {
    let check = true;
    if (validation && fields.name !== null && fields.name.trim().length < 3) {
      check = false;
      props.defineErrorMessage &&
        props.defineErrorMessage("Merci de saisir votre prénom");
    }
    if (check) {
      props.handleStep("next");
      props.defineErrorMessage && props.defineErrorMessage(false);
    }
  };
  return (
    <Step>
      <h1>Qui es-tu ?</h1>
      <Input
        placeholder="Ton petit prénom"
        value={fields.name}
        handleChange={handleChange}
      />
      <Navigation>
        <RoundedButton
          type="next"
          onClick={() => nextStep(true)}
          disabled={isDisable}
        />
        <IconButton
          icon="glasses.svg"
          text="Je reste anonyme"
          onClick={() => nextStep(false)}
        />
      </Navigation>
    </Step>
  );
};

export default StepOne;
