import React, { useState } from "react";
import styled from "styled-components";
import ErrorMessage from "../../_atoms/error-message";

const Div = styled.div`
  width: 100%;
`;

const Step = ({ Component, ...props }: any) => {
  const [errorMessage, setErrorMessage] = useState<false | string>(false);
  return (
    <Div>
      {errorMessage && <ErrorMessage text={errorMessage} />}
      <Component
        fields={props.fields}
        setFields={props.setFields}
        handleStep={props.handleStep}
        defineErrorMessage={setErrorMessage}
      />
    </Div>
  );
};

export default Step;
