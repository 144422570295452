import React from "react";
import styled from "styled-components";

//typage
type RoundedButtonProps = {
  type: string;
  disabled?: boolean;
  onClick?: any;
};

//style
const StyledRoundedButton = styled.button`
  border-radius: 100%;
  border: none;
  width: 100px;
  height: 100px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.light.colorDark};
  box-shadow: ${(props) => props.theme.shadow.main};
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:disabled {
    filter: grayscale(50%) blur(4px);
  }

  &:not(:disabled):hover {
    transform: translateY(-10px);
    box-shadow: ${(props) => props.theme.shadow.hover};
  }
`;

//fonction pour cibler l'icon svg
const computeSrc = (type: string) => {
  let src: string = "";
  switch (type) {
    case "next":
      src = "arrow-next.svg";
      break;
    case "valid":
      src = "check.svg";
      break;
  }
  return src;
};

//composant
const RoundedButton = ({ type, disabled, ...props }: RoundedButtonProps) => (
  <StyledRoundedButton
    onClick={props.onClick !== undefined ? props.onClick : undefined}
    disabled={disabled}
  >
    <img src={`/assets/img/icons/${computeSrc(type)}`} alt="icon" />
  </StyledRoundedButton>
);

export default RoundedButton;
