import styled, { createGlobalStyle, DefaultTheme } from "styled-components";

export const theme: DefaultTheme = {
  fontFamily: {
    content: "Urbanist",
    title: "Philosopher",
  },
  colors: {
    light: {
      main: "white",
      colorLight: "#F1B36B",
      colorDark: "#FE833D",
    },
  },
  shadow: {
    main: "0px 4px 46px rgba(0, 0, 0, 0.1)",
    hover: "0px 20px 30px rgba(0, 0, 0, 0.13)",
    inner: "inset -10px -10px 17px rgba(0, 0, 0, 0.08)",
  },
};

export const GlobalStyle = createGlobalStyle`
  //PHILOSOPHER
  @font-face {
    font-family: 'Philosopher';
    src: url('/assets/fonts/Philosopher/Philosopher-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: 700;
    font-display: fallback; 
  }
  //URBANIST
  @font-face {
    font-family: 'Urbanist';
    src: url('/assets/fonts/Urbanist/Urbanist-Regular.ttf') format('truetype');
    font-style: normal;
    font-weight: 400;
    font-display: fallback; 
  }
  @font-face {
    font-family: 'Urbanist';
    src: url('/assets/fonts/Urbanist/Urbanist-Bold.ttf') format('truetype');
    font-style: normal;
    font-weight: 700;
    font-display: fallback; 
  }
  * { outline:none; box-sizing:border-box;}
  html {
    @media (max-width: 767px) {
      font-size:85%
    }
  }
  body {
    margin: 0;
    font-family: ${(props) =>
      props.theme.fontFamily.content}, Arial, sans-serif;
    font-size: 1.5rem;
    
  }
  h1 {
    font-family: ${(props) => props.theme.fontFamily.title}, Arial, sans-serif;
    color :${(props) => props.theme.colors.light.main};
    font-size: 4rem;
    @media (max-width: 1200px) {
      font-size:3rem
    }
  }
`;

export const Main = styled.main`
  background-color: #f5eadc;
  background-image: url("./assets/img/background.png");
  background-size: cover;
  background-position: center;

  > .container {
    width: 90%;
    max-width: 1200px;
    min-height: 100vh;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    position: relative;
  }
`;
