import React, { useState, useEffect } from "react";
import styled from "styled-components";
//type
import { FieldsInterface } from "./stepInterface";
//components
import Step from "./components/step";
import StepOne from "./components/step-1";
import StepTwo from "./components/step-2";
import StepThree from "./components/step-3";
import FinalStep from "./components/final-step";
import ForbiddenStep from "./components/forbidden-step";
//ajax
import Ajax from "../ajax";

type MultiStepsFormProps = any;

const Div = styled.div`
  width: 100%;
  text-align: center;
  h1 {
    margin-bottom: 90px;
  }
`;

const steps = [ForbiddenStep, StepOne, StepTwo, StepThree, FinalStep];

const saveFeedback = (route: string, datas: any, setCurrentStep: Function) => {
  const ajax = new Ajax();
  ajax.post(route, datas).then((datas) => {
    if (datas.result) setCurrentStep(steps.length - 1);
  });
};

const MultiStepsForm = (props: MultiStepsFormProps) => {
  //states
  const [fields, setFields] = useState<FieldsInterface | Object>({
    name: null,
    humor: null,
    feedback: null,
  });
  const [module, setModule] = useState<number | undefined>(undefined);
  const [currentStep, setCurrentStep] = useState(1);

  //récupération de l'id module dans l'URL
  useEffect(() => {
    const url = window.location.href;
    if (url.indexOf("module") !== -1) {
      const id = parseInt(url.split("=")[1]);
      !isNaN(id) && setModule(id);
    } else {
      setCurrentStep(0);
    }
  }, [setModule, setCurrentStep]);

  //gestion des steps
  const handleStep = (action: string) => {
    switch (action) {
      case "next":
        setCurrentStep(currentStep + 1);
        break;
      case "previous":
        setCurrentStep(currentStep - 1);
        break;
      case "valid":
        saveFeedback("add", { ...fields, module }, setCurrentStep);
        break;
    }
  };

  return (
    <Div>
      <Step
        Component={steps[currentStep]}
        fields={fields}
        setFields={setFields}
        handleStep={handleStep}
      />
    </Div>
  );
};

export default MultiStepsForm;
