import React from "react";
import styled from "styled-components";
//type
import StepInterface from "../stepInterface";

const Step = styled.div``;

const FinalStep = ({ fields, ...props }: StepInterface) => {
  return (
    <Step>
      <h1>Merci pour ton retour !</h1>
    </Step>
  );
};

export default FinalStep;
