export default class Ajax {
  url: string;

  constructor() {
    this.url =
      "https://api.audreyhossepian.fr/feedback-courses/controller.php?route=";
  }

  get = (route: string) => {
    const request = fetch(this.url + route)
      .then((response) => response.json())
      .then((datas) => datas);
    return request;
  };

  post = (route: string, datas: object) => {
    const request = fetch(this.url + route, {
      method: "POST",
      body: this.createFormDatas(datas),
    }).then((response) => response.json());
    return request;
  };

  createFormDatas = (datas: any) => {
    const formDatas = new FormData();
    formDatas.append("test", "testttt");
    for (let data in datas) {
      formDatas.append(data, datas[data]);
    }
    return formDatas;
  };
}
