import React from "react";
import styled from "styled-components";

type HumorCardProps = {
  icon: string;
  label: string;
  onClick: any;
  checked: boolean;
};

const Button = styled.div`
  input {
    display: none;
    &:checked + label {
      background: ${(props) => props.theme.colors.light.colorLight};
      color: ${(props) => props.theme.colors.light.main};
      box-shadow: ${(props) => props.theme.shadow.inner};
    }
  }
  label {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: none;
    padding: 30px;
    /* height: 100%; */
    border-radius: 16px;
    background: ${(props) => props.theme.colors.light.main};
    color: ${(props) => props.theme.colors.light.colorLight};
    box-shadow: ${(props) => props.theme.shadow.main};
    font-weight: 700;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    &:hover {
      transform: translateY(-10px);
      box-shadow: ${(props) => props.theme.shadow.hover};
    }

    img {
      width: 125px;
      height: 125px;
      object-fit: contain;
      margin-bottom: 30px;
    }
  }
`;

const HumorCard = ({ icon, label, onClick, checked }: HumorCardProps) => {
  return (
    <Button>
      <input
        type="radio"
        name="humor"
        id={label.toLowerCase().replaceAll(" ", "")}
        onChange={onClick}
        checked={checked}
      />
      <label htmlFor={label.toLowerCase().replaceAll(" ", "")}>
        <img src={`/assets/img/icons/emojis/${icon}`} alt={label} />
        {label}
      </label>
    </Button>
  );
};

export default HumorCard;
