import React from "react";
import styled from "styled-components";

const Buttons = styled.div`
  margin-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  > *:not(:last-child) {
    margin-right: 30px;
  }
`;

const Navigation = ({ ...props }: any) => {
  return <Buttons>{props.children}</Buttons>;
};

export default Navigation;
